// src/Table.tsx
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import Pagination from './Pagination';
import { TableColumn } from 'types';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import Spinner from 'components/atoms/Spinner';
import ErrorEmptyState from '../../atoms/ErrorEmptyState';
import TableHeaderCell from './TableHeaderCell';

type TableProps = {
  columns: TableColumn[];
  data: any[];
  loading?: boolean;
  loaderHeight?: string
  errorState?: boolean;
  empty?: boolean;
  itemsPerPage: any;
  dataLength?: any;
  getCurrentPage?: (pageNum: number) => void;
};

const AppTable: React.FC<TableProps> = ({ columns, data, itemsPerPage, loading, loaderHeight, errorState, dataLength, getCurrentPage}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const pagesValue = dataLength / itemsPerPage
  const totalPages = dataLength ? Math.ceil( pagesValue ) : Math.ceil(data.length / itemsPerPage);

  // const totalPages = dataLength ? Math.ceil((dataLength / itemsPerPage)) : Math.ceil(data.length / itemsPerPage);
  // const totalPages = Math.ceil(data.length / itemsPerPage);

  const [tableWidth, settableWidth] = useState<number>(0);
  const [bodyWidth, setBodyWidth] = useState<number>(0);
  const [isEndReached, setIsEndReached] = useState(false);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const loadedBodyRef = useRef<HTMLTableSectionElement>(null);

  console.log(tableWidth, bodyWidth)

  useEffect(() => {
    if (tableContainerRef.current) {
      settableWidth(tableContainerRef.current.clientWidth);
    }
  }, []);
  
  useEffect(() => {
    if (loadedBodyRef.current && data.length > 0) {
      setBodyWidth(loadedBodyRef.current.clientWidth);
    }
  }, [data]);

  const handleScroll = () => {
    const container = tableContainerRef.current;
    if (container) {
      setIsEndReached(container.scrollLeft + container.clientWidth === container.scrollWidth);
    }
  };

  const handleScrollLeft = () => {
    const container = tableContainerRef.current;
    if (container) {
      container.scrollTo({
        left: container.scrollLeft - 100, // Adjust as needed
        behavior: 'smooth',
      });
    }
  };

  const handleScrollRight = () => {
    const container = tableContainerRef.current;
    if (container) {
      container.scrollTo({
        left: container.scrollLeft + 100, // Adjust as needed
        behavior: 'smooth',
      });
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = dataLength ? data : data.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    getCurrentPage && getCurrentPage(pageNumber)
  }
  
  return (
    <div className={`relative flex flex-col ${data.length < 10 ? 'min-h-[15vh]' : 'min-h-[60vh]'}`}>
      <div className="overflow-x-auto rounded-lg bg-white" ref={tableContainerRef} onScroll={handleScroll}>
        <table className={`min-w-full mobile:min-h-full ${loading && !loaderHeight && 'min-h-[587px]'} ${loading && loaderHeight && 'min-h-[406px]'} ${errorState && 'min-h-[300px]'} divide-y divide-gray-200`}>
          {/* <TableHeader columns={columns}/> */}
          <thead ref={loadedBodyRef}>
            <tr>
              {columns.map((column, index) => (
                <TableHeaderCell key={index} label={column.label} />
              ))}
            </tr>
          </thead>
          { loading ?
            <div className={`absolute z-10 flex items-center py-10 justify-center w-full h-[80%]`}>
              <Spinner
                text="Loading..."
              />
            </div> 
          
          :!errorState && data.length > 0 ? 
            <TableBody columns={columns} data={currentData}/>
          
          : <tbody>
              <ErrorEmptyState img={true}/>
            </tbody>
          }
        </table>
        {(tableWidth < bodyWidth) && <button
            className={`
              absolute top-0 bottom-0 h-6 w-6 flex items-center justify-center self-start rounded-full bg-gray-100 text-gray-800 ${isEndReached ? "right-[-10px]" : "left-[-10px]"}
            `}
            onClick={isEndReached ? handleScrollLeft : handleScrollRight}
          >
            {isEndReached ? (
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#000000"} fill={"none"}>
                <path d="M15 6C15 6 9.00001 10.4189 9 12C8.99999 13.5812 15 18 15 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#000000"} fill={"none"}>
                <path d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            )}
          </button>}
      </div>
      {data.length > 0 && totalPages > 1 && !errorState && <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        totalData={dataLength}
        onPageChange={handlePageChange}
      />}
    </div>
  );
};

export default AppTable