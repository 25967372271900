import React, { useState, useEffect } from 'react'
import SettingsNavItem from 'components/atoms/SubNavItem'
import { useLocation, useNavigate } from 'react-router-dom'
import Button from 'components/atoms/Button'
import addIcon from '../../assets/svg/add_icon.svg'
import { getValues } from 'services/storage'

const SubNav = (props) => {
    const [selected, setSelected] = useState("");
    const accountType = getValues('pocketType');

    useEffect(() => {
        setSelected(props.navData[0].title === `${props.defaultItem}` ? `${props.defaultItem}` : selected)
    }, [accountType])

    const navItemFunc = (item) => {
        return (
            <SettingsNavItem
                title={item.title}
                active={item.title === selected}
                icon={item.icon}
                iconActive={item.iconActive}
                link={`${props.absoluteLinkPath + item.title.replace(" ", "_").toLowerCase()}`}
                onClick={e => setSelected(e.target.dataset.name)}
                styleType={props.styleType}
            />
        )
    }

    const navContent = accountType === 'PRIMARY_POCKET'
        ? props.navData.map(item => navItemFunc(item))
        : props.navData.filter(item => item.title !== 'Bulk').map(item => navItemFunc(item));

    return (
        <div className={`${props.addedNavStyle} flex items-center w-full overflow-x-auto custom_container`}>
            <div className='flex w-fit gap-5'>
                {navContent}
            </div>
        </div>
    )
}

export default SubNav;
