import React, { useCallback, useState } from 'react';
import axios from "services/axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FormInput from 'components/atoms/FormInput';
import showPwd from "../../../assets/svg/show.svg";
import hidePwd from "../../../assets/svg/hide.svg";
import Button from 'components/atoms/Button';
import Alert from 'components/atoms/Alert';
import { BASE_URL } from 'services/http';
import { handleException } from "utils";
import Spinner from 'components/atoms/Spinner';

const ResetPassword = () => {
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [errorState, setErrorState] = useState(false);

    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [passwordErr, setPasswordErr] = useState("");

    const location = useLocation();
    const navigate = useNavigate();

    const formComplete = oldPassword && newPassword && confirmPassword;
    const disableBtn = !formComplete || loading;

    const validateUser = async (userData: any) => {
        try {
            const res = await axios.post(`${BASE_URL}authenticate`, userData, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            
            if(res?.data?.responseCode === '00'){
                await ChangePasswordFunc(res.data.data.bearerToken);
            }else{
                setLoading(false);
                Alert("error", res.data.message);
            }

        } catch (error) {
            setLoading(false);
            Alert("error", error);
        }
    }

    const ChangePasswordFunc = useCallback(async (bearerToken: string) => {
        setLoading(true);
        try {
            const res = await axios.post(
                `${BASE_URL + 'user/change-password'}`,
                {
                    currentPassword: oldPassword,
                    newPassword: newPassword,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${bearerToken}`,
                        // "Public-Key": `${publicKey}`,
                    },
                }
            );
            if (res.data.code === "error") {
                setLoading(false);
                Alert("error", res.data.message);
            } else {
                setLoading(false);
                Alert("success", res.data.message);
                navigate('/auth/login');
            }
        } catch (error) {
            setLoading(false);
            setErrorState(true);
            handleException(error);
        }
    }, [oldPassword, newPassword, confirmPassword]);

    const handleSubmit = (e) => {
        e.preventDefault(); 

        if (!oldPassword || !newPassword || !confirmPassword) {
            Alert("error", "Incomplete Form data input, please fill all fields");
            return;
        }

        if (newPassword !== confirmPassword) {
            Alert("error", "Password do not Match");
            return;
        }

        return validateUser({
            email: location?.state?.email,
            password: oldPassword,
        });
    }

    return (
        <div className="text-center bg-white py-6 w-full rounded-lg">
            <span className="text-left flex flex-col gap-5">
                <p className="font-bold text-2xl leading-7">Reset Password</p>
                {/* <p className="font-normal text-base leading-7 mb-5 text-Black2"></p> */}
            </span>

            <FormInput
                type={showPassword ? "text" : "password"}
                name="passwd"
                placeholder="********"
                inputStyle="border border-transparent text-Black2 flex-row-reverse"
                label="Current Password"
                labelStyle="font-normal text-left text-Black2 mx-0 mt-6"
                onChange={(e) => setOldPassword(e.target.value)}
                value={oldPassword}
                validationErr={passwordErr}
                img={showPassword ? showPwd : hidePwd}
                imgStyle="cursor-pointer w-5 h-5 block"
                imgOnClick={() => setShowPassword((prevState) => !prevState)}
            />
            <FormInput
                type={showPassword ? "text" : "password"}
                name="passwd"
                placeholder="********"
                inputStyle="border border-transparent text-Black2 flex-row-reverse"
                label="New Password"
                labelStyle="font-normal text-left text-Black2 mx-0 mt-6"
                onChange={(e) => setNewPassword(e.target.value)}
                value={newPassword}
                validationErr={passwordErr}
                img={showPassword ? showPwd : hidePwd}
                imgStyle="cursor-pointer w-5 h-5 block"
                imgOnClick={() => setShowPassword((prevState) => !prevState)}
            />
            <FormInput
                type={showPassword ? "text" : "password"}
                name="passwd"
                placeholder="********"
                inputStyle="border border-transparent text-Black2 flex-row-reverse"
                label="Confirm Password"
                labelStyle="font-normal text-left text-Black2 mx-0 mt-6"
                onChange={(e) => setConfirmPassword(e.target.value)}
                value={confirmPassword}
                validationErr={passwordErr}
                img={showPassword ? showPwd : hidePwd}
                imgStyle="cursor-pointer w-5 h-5 block"
                imgOnClick={() => setShowPassword((prevState) => !prevState)}
            />
            <Button
                // onClick={ChangePasswordFunc}
                onClick={handleSubmit}
                btnType="submit"
                btnText={
                    loading ? (
                        <Spinner text="Loading..." textStyle="text-white" />
                    ) : (
                        "Change Password"
                    )
                }
                btnStyle="mt-10 bg-black text-SecondaryAccent5 font-semibold text-sm leading-7 px-5 py-1 !rounded-lg w-full"
                disabled={disableBtn}
            />
            <span className="flex justify-left mt-5">
                <Link
                    to="/auth/login"
                    className="text-DarkBg3 hover:text-DarkBg3 font-bold text-sm leading-7 hover:underline hover:transition-all"
                >
                    Return to Login
                </Link>
            </span>
        </div>
    )
}

export default ResetPassword;
