import React from "react";
import { Outlet } from "react-router-dom";
import PageTitle from "components/atoms/PageTitle";
import SubNav from "components/organisms/SubNav";
import single_w from "../../../assets/svg/single-transfer-red.svg";
import single_b from "../../../assets/svg/single-transfer-b.svg";
import bulk_w from "../../../assets/svg/bulk-transfer-red.svg";
import bulk_b from "../../../assets/svg/bulk-transfer-b.svg";
import { getValues, setValues } from "services/storage";

const TransferFunds = () => {
  const NavData = [
    { title: "Bulk", available: true, icon: bulk_b, iconActive: bulk_w },
    { title: "Single", available: true, icon: single_b, iconActive: single_w },
  ];

  return (
    <>
      <div className="flex flex-col gap-5">
        <PageTitle pageTitle="Send Money" pageTitleStyle="font-bold" />
        <SubNav
          navData={NavData}
          defaultItem="Single"
          absoluteLinkPath="/dashboard/disbursement/"
          addedNavStyle="desktop:!gap-10 justify-start border-b"
          styleType="borderless"
        />
        {/* {accountType === "PRIMARY_POCKET" && (
          <div style={{ backgroundColor: "white" }}>
            <div className="text-center rounded-b text-red-900 px-4 py-3 shadow-lg w-full">
              {" "}
              This Page is currenly undergoing Maintanance
            </div>
          </div>
        )} */}
        <div className="bg-Background5 rounded-[10px] pt-3 px-10 mobile:py-5 mobile:px-0 min-h-[60vh] mobile:min-h-[65vh] max-h-[85%] h-fit overflow-y-scroll">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default TransferFunds;
